import React from 'react'
import '../styles/stars.scss'

const Stars: React.FC = () => (
	<div className="stars-cont">
		<div className="stars">
			<div className="small"></div>
			<div className="medium"></div>
			<div className="big"></div>
		</div>
	</div>
)

export default Stars
